.marker-collapsed-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    filter: drop-shadow(4px -1px 3px rgba(0, 0, 0, 0.4));
}

.marker-collapsed-inner {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* .marker-collapsed-outer:hover {
    transition: all 0.1s ease-in-out;
    width: 42px;
    height: 42px;
} */

.marker-expanded {
    width: 30px;
    height: 30px;
}

.marker-text {
    font: 'Roboto', sans-serif;
    font-size: .8rem;
}

.marker-expanded-left-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-top-left-radius: 50%;
    filter: drop-shadow(4px 2px 3px rgba(0, 0, 0, 0.4));
}

.marker-expanded-left-inner {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.marker-expanded-right {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 5px 5px;
    width: 180px;
    height: 40px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    font: 'Roboto', sans-serif;
    font-size: .6rem;
    animation-name: marker-expand-animation;
    animation-duration: .5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes marker-expand-animation{
    from{
        width: 0px;
        font-size: 0rem;
        color: rgba(0, 0, 0, 0);
        filter: drop-shadow(4px 2px 3px rgba(0, 0, 0, 0.1));
    }
    to{
        width: 170px;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
        color: black;
        filter: drop-shadow(4px 2px 3px rgba(0, 0, 0, 0.4));
    }
}

.marker-expanded-details {
    overflow-x: hidden;
    overflow-y: hidden;
}

.marker-expanded-right span {
    font-size: .55rem;
    color: black;
}

/* COLORS */

.outer-green {
    background-color: #33CC66;
}

.outer-purple {
    background-color: #CC66FF;
}

.outer-orange {
    background-color: #FF9933;
}

.inner-green {
    background-color: rgb(167, 241, 192);
}

.inner-purple {
    background-color: rgb(241, 192, 241);
}

.inner-orange {
    background-color: rgb(241, 224, 192);
}