#welcome-page-bg {
   position: absolute;
   /* top: 56px; */
   height: 100vh;
   width: 100vw;
   z-index: 0;
}

/* #welcome-page-overlay{
   position: absolute;
   top: 56px;
   left: 0px;
   height: calc(100vh - 56px);
   width: 100vw;
   z-index: 1;
   background: linear-gradient(rgba(255, 255, 255, 0), rgba(16, 18, 53, 0.412), rgba(16, 18, 53, 0.653), rgba(16, 18, 53, 0.899), rgb(16, 18, 53));
} */

#welcome-page-content {
   z-index: 2;
}

#welcome-page-control {
   z-index: 10;
}

/* #welcome-page-img {
   width: 100vw;
   height: auto;
} */