.restaurant-card {
    border: 1px solid #000000;
    border-radius: 15px;
    filter: drop-shadow(-4px 2px 0px rgba(-10, 0, 0, .75));
    width: 90%;
    background-color: rgb(255, 255, 255);
    margin: 10px auto;
}

.restaurant-card:hover {
    /* transition: all 0.1s ease-in-out; */

    border: 1px solid #000000;
    border-radius: 15px;
    filter: drop-shadow(-5px 3px 0px rgba(-10, 0, 0, .75));
    width: 90%;
    background-color: rgb(255, 255, 255);
    margin: 10px 5px 10px 10px;
    cursor: pointer;
}

.card-header-rank {
    width: 15%;
    height: auto;
    max-width: 48px;
    max-height: 48px;
}

.card-header-title {
    width: 75%;
}

.card-header-options {
    margin: 7px 2px 2px 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-details {
    display: flex;
    flex-direction: column;
    margin-left: 1%;
    margin-bottom: 10px;
}

.card-image.image {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
}

.card-image.container {
    position: relative;
    width: 100%;
    height: 120px;
}