@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/abeezee');
@import url('https://use.typekit.net/hka2azd.css');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300&display=swap');

* {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.header-font {
  font-family: poleno, sans-serif;
  font-style: normal;
  font-weight: 400;                 
}

.pp-yellow-bg {
  background-color: #F3A827;
}

.left-down-shadow {
  /* filter: drop-shadow(-10px 4px 0px #333333); */
  filter: drop-shadow(-10px 4px 15px #333333);
}

.smooth-scroll {
  overflow: auto;
  scroll-behavior: smooth;
}

.white-scroller::-webkit-scrollbar {
  width: 12px;
}

.white-scroller::-webkit-scrollbar-thumb {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(153, 153, 153);
  border-radius: 999px;
  box-shadow: none;
}

.white-scroller::-webkit-scrollbar-track {
  background-color: transparent;
  border: none;
}

/* #restaurant-card-scroller::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

body {
  background-color: #F3A827;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

